import './style.scss'

// @ts-ignore
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import {ApolloProvider} from '@apollo/client'
import {
  churnZeroPlugin,
  CoreProvider,
  Router,
} from '@casecommons/cbp-core-typescript'
// @ts-ignore
import {env} from '@casecommons/cbp-env'
import {createGQLClient} from '@casecommons/cbp-graphql-client'
import {
  AuthenticationProvider,
  getAuthenticationHeaders,
  redirectToLogin,
} from '@casecommons/cbp-react-auth'
import {LoadingPage} from '@casecommons/cbp-undercase'
import Analytics from 'analytics'
import React, {useEffect, Suspense} from 'react'
import {render} from 'react-dom'

import {useAnalytics, AnalyticsProvider} from 'use-analytics'

import useCurrentUser from './hooks/current-user'

import routesConfig from './routes-config'

const apolloClient = createGQLClient({
  getAuthenticationHeadersFn: getAuthenticationHeaders,
  onError: (error) => error && error.statusCode === 401 && redirectToLogin(),
  uri: `${env('CBP_API_BASE_URL')}/global_graphql`,
})

const {gaId, gtmId, domain, churnZeroAppKey, churnZeroSubdomain} = (
  window as any
).appInfo

const analytics = Analytics({
  plugins: [
    googleAnalytics({
      measurementIds: [gaId],
      dataLayerName: 'cbpDataLayer',
    }),
    googleTagManager({
      containerId: gtmId,
      dataLayerName: 'cbpDataLayer',
    }),
    churnZeroPlugin({
      appKey: churnZeroAppKey,
      subdomain: churnZeroSubdomain,
      moduleName: 'Home',
    }),
  ],
})

/**
 * If the user ID includes one of the casebook domains, disable the "churn-zero" plugin.
 */
const currentUserId = localStorage.getItem('__user_id')
const casebookDomains = ['@casebook.net', '@sysvine.com']

if (
  currentUserId &&
  casebookDomains.some((currentDomain) => currentUserId.includes(currentDomain))
) {
  analytics.plugins.disable(['churn-zero'], () => {})
}

analytics.on('trackStart', ({payload}) => {
  const {properties} = payload
  properties.appName = 'Home'
})

const Core = () => {
  const currentUser = useCurrentUser()
  const {identify, page} = useAnalytics()
  const {firstName, name, lastName, email, id, tenant, volunteer} = currentUser
  const {subdomain} = (window as any).tenantInfo
  const companyId = `${subdomain}.${domain}`
  const userId = `${email}_${companyId}`

  useEffect(() => {
    identify(userId, {
      user_id: userId,
      email,
      name,
      firstName,
      lastName,
      volunteer,
      tenantId: tenant?.id,
      tenantName: tenant?.name,
      custom_attributes: {
        userId: id,
      },
      company: {
        id: companyId,
        name: tenant?.name,
        custom_attributes: {
          subdomain,
          tenantId: tenant?.id,
        },
      },
    })
    page()
  }, [id])

  return (
    <CoreProvider
      routesConfig={routesConfig}
      currentUser={currentUser}
      currentApplication='home'
    >
      <Suspense fallback={<LoadingPage />}>
        <Router />
      </Suspense>
    </CoreProvider>
  )
}

const App = () => (
  <AuthenticationProvider>
    <ApolloProvider client={apolloClient}>
      <AnalyticsProvider instance={analytics}>
        <Core />
      </AnalyticsProvider>
    </ApolloProvider>
  </AuthenticationProvider>
)

if ('env' in window && (window as any).env.CBP_CDN_BASE_URL) {
  __webpack_public_path__ = `${
    (window as any).env.CBP_CDN_BASE_URL
  }${__webpack_public_path__}`
}

render(<App />, document.getElementById('root'))
